<template>
    <div>
        <div id="loadingMessage">
            🎥 無法存取您的相機 (請允許相機使用權限)
        </div>
        <canvas id="canvas" hidden style="width: 100%"></canvas>
        <div id="output">
            <div class="text-normal" id="messageContainer">
                <b id="outputMessage"></b>
            </div>
        </div>
    </div>
</template>

<script>
import jsQR from "jsqr";

export default {
    name: "QRCodeScanner",
    data: function() {
      return {
        isScan: true,
        storeCode: "",
        video: null,
        availableTypes: ['S', 'C']
      }
    },
    
    mounted: function () {
        console.log("mounted");
        this.scanCode();
    },
    methods: {
        scanCode() {
            var video = document.createElement("video");
            this.video = video;
            this.$emit('create-video', this.video);
            // Use facingMode: environment to attemt to get the front camera on phones
            this.startStreamedVideo(video, this.tick);
        },
        scanAction(code) {
            if (this.checkScanDataAvaliable(code)) return true;
            return false;
        },
        checkScanDataAvaliable(code) {
            if (code && code.data && this.availableTypes.includes(code.data.split(':')[0])) {
                return true;
            }
            return false;
        },
        goToUsePoint() {
            this.$router.push({path: 'consumption', query: {storeCode: this.storeCode}});
        },
        stopStreamedVideo(videoElem) {
            const stream = videoElem.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(function (track) {
                track.stop();
            });

            videoElem.srcObject = null;
        },
        drawLine(canvas, begin, end, color) {
            canvas.beginPath();
            canvas.moveTo(begin.x, begin.y);
            canvas.lineTo(end.x, end.y);
            canvas.lineWidth = 4;
            canvas.strokeStyle = color;
            canvas.stroke();
        },
        drawText(canvas, text, begin, color = "#D65792") {
            canvas.font = "20px Arial";
            canvas.textAlign = "center";
            canvas.fillStyle = color;
            canvas.fillText(text, begin.x, begin.y);
        },
        drawCorners(canvas, width, height, color) {
            let align = 3;
            let lineWidth = 6;
            let lineLength = 35;

            let widthPositionWeight = 1 / 5.2;
            let heightPositionWeight = 1 / 3;

            let p1 = {
                x: width * widthPositionWeight,
                y: height * heightPositionWeight
            };
            let p2 = {
                x: width * (1 - widthPositionWeight),
                y: height * heightPositionWeight
            };
            let p3 = {
                x: width * widthPositionWeight,
                y: height * (1 - heightPositionWeight)
            };
            let p4 = {
                x: width * (1 - widthPositionWeight),
                y: height * (1 - heightPositionWeight)
            };

            // top left
            canvas.beginPath();
            canvas.lineWidth = lineWidth;
            canvas.strokeStyle = color;
            canvas.moveTo(p1.x - align, p1.y);
            canvas.lineTo(p1.x + lineLength, p1.y);
            canvas.stroke();

            canvas.beginPath();
            canvas.lineWidth = lineWidth;
            canvas.strokeStyle = color;
            canvas.moveTo(p1.x, p1.y);
            canvas.lineTo(p1.x, p1.y + lineLength);
            canvas.stroke();

            // top right
            canvas.beginPath();
            canvas.lineWidth = lineWidth;
            canvas.strokeStyle = color;
            canvas.moveTo(p2.x + align, p2.y);
            canvas.lineTo(p2.x - lineLength, p2.y);
            canvas.stroke();

            canvas.beginPath();
            canvas.lineWidth = lineWidth;
            canvas.strokeStyle = color;
            canvas.moveTo(p2.x, p2.y);
            canvas.lineTo(p2.x, p2.y + lineLength);
            canvas.stroke();

            // botton left
            canvas.beginPath();
            canvas.lineWidth = lineWidth;
            canvas.strokeStyle = color;
            canvas.moveTo(p3.x, p3.y + align);
            canvas.lineTo(p3.x, p3.y - lineLength);
            canvas.stroke();

            canvas.beginPath();
            canvas.lineWidth = lineWidth;
            canvas.strokeStyle = color;
            canvas.moveTo(p3.x + lineLength, p3.y);
            canvas.lineTo(p3.x, p3.y);
            canvas.stroke();

            // botton right
            canvas.beginPath();
            canvas.lineWidth = lineWidth;
            canvas.strokeStyle = color;
            canvas.moveTo(p4.x, p4.y + align);
            canvas.lineTo(p4.x, p4.y - lineLength);
            canvas.stroke();

            canvas.beginPath();
            canvas.lineWidth = lineWidth;
            canvas.strokeStyle = color;
            canvas.moveTo(p4.x, p4.y);
            canvas.lineTo(p4.x - lineLength, p4.y);
            canvas.stroke();
        },
        drawCode(canvas, code) {
            this.drawLine(
                canvas,
                code.location.topLeftCorner,
                code.location.topRightCorner,
                "#FF3B58"
            );
            this.drawLine(
                canvas,
                code.location.topRightCorner,
                code.location.bottomRightCorner,
                "#FF3B58"
            );
            this.drawLine(
                canvas,
                code.location.bottomRightCorner,
                code.location.bottomLeftCorner,
                "#FF3B58"
            );
            this.drawLine(
                canvas,
                code.location.bottomLeftCorner,
                code.location.topLeftCorner,
                "#FF3B58"
            );
        },
        tick() {
            var canvasElement = document.getElementById("canvas");
            if(canvasElement) {
                var canvas = canvasElement.getContext("2d");
            var loadingMessage = document.getElementById("loadingMessage");
            var outputContainer = document.getElementById("output");
            var outputMessage = document.getElementById("outputMessage");

            loadingMessage.innerText = "⌛ Loading video...";
            if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
                loadingMessage.hidden = true;
                canvasElement.hidden = false;
                outputContainer.hidden = false;

                // canvasElement.height = video.videoHeight;
                // canvasElement.width = video.videoWidth;
                canvasElement.height = this.video.videoHeight * 1;
                canvasElement.width = this.video.videoWidth * 1;

                canvas.drawImage(
                    this.video,
                    0,
                    0,
                    canvasElement.width,
                    canvasElement.height
                );

                // this.drawText(
                //     canvas,
                //     "請掃描QR Code", {
                //     x: canvasElement.width / 2,
                //     y: canvasElement.height - 20
                // });
                this.drawCorners(
                    canvas,
                    canvasElement.width,
                    canvasElement.height,
                    "#FFFFFF"
                );

                var imageData = canvas.getImageData(
                    0,
                    0,
                    canvasElement.width,
                    canvasElement.height
                );
                var code = jsQR(
                    imageData.data,
                    imageData.width,
                    imageData.height,
                    {
                        inversionAttempts: "dontInvert"
                    }
                );
                if (code) {
                    console.log("code: ", code)
                    this.drawCode(canvas, code);
                    if (this.scanAction(code)) {
                        this.isScan = false;
                        let type = code.data.split(':')[0];
                        let data = code.data.split(':')[1];
                        switch (type) {
                            case 'S':
                                this.$router.push({path: "/consumption", query: {storeCode: data}});
                                break;
                            case 'C':
                                this.$router.push({path: "/transfer", query: {transferCode: data}});
                                break;
                            default:
                                break;
                        }
                    } else {
                        outputMessage.innerText =
                            "資料異常";
                        this.isScan = true;
                        this.drawText(
                            canvas,
                            "商店代碼異常",
                            {
                                x: canvasElement.width / 2,
                                y: canvasElement.height / 2
                            },
                            "red"
                        );
                        this.drawCorners(
                            canvas,
                            canvasElement.width,
                            canvasElement.height,
                            "red"
                        );
                    }
                } else {
                    this.isScan = true;
                    outputMessage.innerText = "";
                }
            }
            if (this.isScan) {
                let hz = 30;
                setTimeout(() => {
                    requestAnimationFrame(this.tick);
                }, 1/hz * 1000);
            }
            }
            
        },
        startStreamedVideo(videoElem, tick) {
            const constraints = {
                advanced: [
                    {
                        aspectRatio: 1
                    }
                ]
            };
            // Use facingMode: environment to attemt to get the front camera on phones

            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        facingMode: "environment"
                    }
                })
                .then((stream) => {
                    const track = stream.getVideoTracks()[0];
                    track.applyConstraints(constraints);
                    videoElem.srcObject = stream;
                    videoElem.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
                    videoElem.play();
                    this.$emit('video-ready', true);
                    requestAnimationFrame(tick);
                })
                .catch((getUserMediaErr) => {
                    console.log("getUserMediaErr: ", getUserMediaErr)
                    this.$emit('video-ready', false);
                })
        }
    }
};
</script>


