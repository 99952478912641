<template>
    <div class="body fixed mobile">
        <!-- Loading Spinner -->
        <div class="spinner" v-show="loading">
            <div><div></div></div>
        </div>
        <!-- Header -->
        <section class="header scan">
            <div class="icon"></div>
            <span class="fL">掃碼</span>
            <a class="icon iconButton third">
                <!-- <font-awesome-icon
                    icon="fa-regular fa-images"
                    size="xl"
                ></font-awesome-icon> -->
            </a>
        </section>
        <!-- Main -->
        <section class="main scan">
            <!-- <a class="button fit rounded" @click.prevent="popCode()"
                ><span class="fS">手動輸入代碼</span></a
            > -->
            <QRCodeScanner
                @create-video="videoHandler"
                @video-ready="getReady($event)"
            />
            <div :class="['input', 'rounded', { error: isError }]">
                <input
                    type="text"
                    class="fM"
                    placeholder="手動輸入代碼"
                    v-model="storeCode"
                    @keyup.enter="goToUsePoint()"
                />
                <span v-if="storeCode" class="fS error message">商店代碼無效</span>
                <span v-else class="fS error message">請輸入商店代碼，或使用相機掃碼</span>
                <a class="icon iconButton" @click.prevent="goToUsePoint()"
                    ><font-awesome-icon icon="fa-solid fa-arrow-right"
                /></a>
            </div>
            <a class="button fit rounded" @click.prevent="popCode();refreshMemberCode();"><span class="fS">我的條碼</span></a>
        </section>
        <!-- Popup (Code) -->
        <section id="popCode" class="popup scan popCode">
            <div class="popBody">
                <a class="close"
                    ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
                ></a>
                <section class="popHeader">
                    <span class="fL primary center">我的條碼</span>
                </section>
                <section class="popMain">
                    <div class="flexH width between">
                        <div class="img profile">
                            <img :src="userImageUrl" />
                        </div>
                        <div class="flexV width">
                            <span class="fL">{{ displayName }}</span>
                            <span class="fM gray">{{
                                displayIdentityNumber
                            }}</span>
                        </div>
                        <div class="flexV center">
                            <a class="refresh icon iconButton white" @click.prevent="refreshMemberCode()">
                                <font-awesome-icon icon="fa-solid fa-arrows-rotate"></font-awesome-icon>
                            </a>
                            <span class="fXS gray center">{{displayTimerToMinutes()}}<br>更新</span>
                        </div>
                    </div>
                    <div class="qrcode flexH width middle">
                        <div class="img">
                            <QrcodeVue :value="memberCode" size="250" level="H"/>
                        </div>
                    </div>
                    <div class="qrcode flexH width center">
                        <barcode :value="memberCode" format="code128" width="2">
                            {{memberCode}}
                        </barcode>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState } from "vuex";
import QRCodeScanner from "@/components/QRCodeScanner.vue";
import VueBarcode from 'vue-barcode';
import QrcodeVue from "qrcode.vue";

export default {
    name: "Scanner",
    components: {
        QRCodeScanner,
        'barcode': VueBarcode,
        QrcodeVue,
    },
    data: function () {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            storeCode: "",
            video: null,
            isError: false,
            loading: true,
            memberCode: "",
            intervalId: "",
            defaultTimerSec: 300,
            timer: 300
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        console.log(to, from);
        this.stopStreamedVideo(this.video);
        next();
    },
    computed: {
        ...mapState(["user"]),
        displayName() {
            return this.user ? this.user.displayName : "";
        },
        displayIdentityNumber() {
            return this.user && this.user.user && this.user.user._json
                ? this.hideIdentityNumber(this.user.user._json.idno)
                : "";
        },
        userImageUrl() {
            return this.user && this.user.user && this.user.user._json
                ? this.user.user._json.picture
                : "";
        }
    },
    mounted: function () {
        console.log("mounted");
        var popCode = document.getElementById("popCode");
        var close = popCode.getElementsByClassName("close")[0];
        close.onclick = () => {
            this.popClose();
        };

        window.onclick = (event) => {
            if (event.target == popCode) {
                this.popClose();
            }
        };
    },
    methods: {
        hideIdentityNumber(str) {
            let patt = /(^[a-zA-Z]{1})(\d{7})(\d{2})/g;
            return str.replace(patt, function(match, start, middle, tail) {
                return start + '*'.repeat(middle.length) + tail;
            })
        },
        displayTimerToMinutes() {
            let mins = Math.floor(this.timer / 60);
            let secs = (this.timer % 60).toString().padStart(2, '00');
            return `${mins}:${secs}`;
        },
        refreshMemberCode() {
            this.loading = true;
            this.periodRefreshMemberCode();
            this.getMemberCode()
            .then(memberCodeRes => {
                this.memberCode = memberCodeRes.data.oneTimeKey;
                this.loading = false;

            })
            .catch(memberCodeErr => {
                console.log("memberCodeErr: ", memberCodeErr)
                this.loading = false;
            });
        },
        clearTimer() {
            clearInterval(this.intervalId);
            this.timer = this.defaultTimerSec;
            this.memberCode = "";
            this.intervalId = "";
        },
        periodRefreshMemberCode() {
            this.clearTimer();
            this.intervalId = setInterval(() => {
                this.timer--;
                if(this.timer <= 0) {
                    this.refreshMemberCode();
                }
            }, 1000);
        },
        getMemberCode() {
            let config = {
                url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/one-time-keys/pay`,
                method: "POST"
            }
            return this.$http(config);
        },
        dummyMemberCode() {
            let result = {
                "oneTimeKey": "12771205352200000" + Math.floor(Math.random() * 10000)
            };
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({data: result})
                }, 300);
            });
        },
        parseQrcode(qrcodeData, userLineId) {
            let config = {
                url: `/scanner/parse`,
                method: "POST",
                data: {
                    userLineId,
                    qrcodeData
                }
            };
            return this.$http(config);
        },
        goToUsePoint() {
            if (!this.storeCode) {
                this.isError = true;
                return;
            }
            this.getAppointedStoreInfo(this.storeCode)
                .then((getAppointedStoreInfoRes) => {
                    console.log(
                        "getAppointedStoreInfoRes: ",
                        getAppointedStoreInfoRes
                    );
                    this.$router.push({
                        path: "consumption",
                        query: { storeCode: this.storeCode }
                    });
                })
                .catch((getAppointedStoreInfoErr) => {
                    console.log(
                        "getAppointedStoreInfoErr: ",
                        getAppointedStoreInfoErr
                    );
                    this.isError = true;
                });
        },
        videoHandler(e) {
            this.video = e;
        },
        stopStreamedVideo(videoElem) {
            const stream = videoElem.srcObject;
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach(function (track) {
                    track.stop();
                });
                videoElem.srcObject = null;
            }
        },
        popCode() {
            var popCode = document.getElementById("popCode");
            popCode.style.display = "flex";
        },
        popClose() {
            var popCode = document.getElementById("popCode");
            popCode.style.display = "none";
            document.body.style.overflow = "scroll";
            this.clearTimer();
        },
        getAppointedStoreInfo(storeCode) {
            console.log(storeCode);
            var config = {
                method: "get",
                url: `${this.apiHost}/stores/v1/merchants/${this.merchantId}/store-codes/${storeCode}`,
                headers: {}
            };

            return this.$http(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                    throw error;
                });
        },
        getReady(isReady) {
            if (isReady) {
                this.loading = false;
            } else {
                //
                this.loading = false;
            }
        }
    },
    watch: {
        storeCode: function () {
            this.isError = false;
        }
    }
};
</script>


<style>
.vue-barcode-element {
    width: 100%;
}
</style>